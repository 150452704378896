import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files
import enTranslation from "./locales/en/translation.json";
import plTranslation from "./locales/pl/translation.json";

// Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: enTranslation,
  },
  pl: {
    translation: plTranslation,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", 
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;