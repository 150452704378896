
import React, {Component} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";


class CliftonSelector extends Component {
  constructor(props) {
    super(props);

    this.cliftonStrenghts = {
      achiever: props.t('strength/achiever'),
      activator: props.t('strength/activator'),
      adaptability: props.t('strength/adaptability'),
      analytical: props.t('strength/analytical'),
      arranger: props.t('strength/arranger'),
      belief: props.t('strength/belief'),
      command: props.t('strength/command'),
      communication: props.t('strength/communication'),
      competition: props.t('strength/competition'),
      connectedness: props.t('strength/connectedness'),
      consistency: props.t('strength/consistency'),
      context: props.t('strength/context'),
      deliberative: props.t('strength/deliberative'),
      developer: props.t('strength/developer'),
      discipline: props.t('strength/discipline'),
      empathy: props.t('strength/empathy'),
      focus: props.t('strength/focus'),
      futuristic: props.t('strength/futuristic'),
      harmony: props.t('strength/harmony'),
      ideation: props.t('strength/ideation'),
      includer: props.t('strength/includer'),
      individualization: props.t('strength/individualization'),
      input: props.t('strength/input'),
      intellection: props.t('strength/intellection'),
      learner: props.t('strength/learner'),
      maximizer: props.t('strength/maximizer'),
      positivity: props.t('strength/positivity'),
      relator: props.t('strength/relator'),
      responsibility: props.t('strength/responsibility'),
      restorative: props.t('strength/restorative'),
      self_assurance: props.t('strength/self_assurance'),
      significance: props.t('strength/significance'),
      strategic: props.t('strength/strategic'),
      woo: props.t('strength/woo')
    }

    this.state = {
      value: this.props.value ? this.props.value : []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value ? this.props.value : []
      });
    }
  }

  addValue = (event) => {
    if (event.target.value) {
      const value = [...this.state.value]; 
      value.push(event.target.value);

      // Make unique
      const uvalue = [...new Set(value)];

      this.setState({value: uvalue})
      this.props.onChange(uvalue)
    }
  }

  deleteValue = (item) => {
    const value = [...this.state.value]; 
    const index = value.indexOf(item);
    if (index > -1) {
      value.splice(index, 1); 
    }
    this.setState({value: value})
  }

  render() {
    var strengthSelector = '';
    if (this.state.value.length < 5 ) {
      strengthSelector = <Col><Form.Select
        required={this.props.required}
        value={""}
        onChange={this.addValue}>
        <option value="">{this.props.t('form/placeholder/clifton_strengths')}</option>
        {
          Object.keys(this.cliftonStrenghts).map((csKey) => {
            if (!this.state.value.includes(csKey)) {
              return <option value={csKey}>{this.cliftonStrenghts[csKey]}</option>
            }
          })
        }
      </Form.Select></Col>
    }
    
    return <Row>
      <Col xs="12"><Form.Label as={Col} xs={12} >{this.props.t('form/label/clifton_strengths')}</Form.Label></Col>
      {
        this.state.value.map((cs, index) => {
          var i = index + 1
          return <Col className="d-grid mb-3"><Button variant="primary" className="btn-block d-flex flex-row justify-content-between" onClick={() => this.deleteValue(cs)}>{i + ". " + this.cliftonStrenghts[cs]}<IoMdClose size={24}/></Button></Col>
        })
      }
      {strengthSelector}
      </Row>
  }
}


export default withTranslation()(CliftonSelector)