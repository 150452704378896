import React, {Component} from 'react';
import { Image, Spinner } from 'react-bootstrap';

class FlashCard extends Component {
  constructor(props) {
    super(props); 
  }
 
  render() {
    return (
    <section className="container my-3">
      <div className="pane-content my-3 text-center">
        <p>{this.props.message}</p>
        <div className="my-4"><Spinner
        as="span"
        animation="border"     
        role="status"
        aria-hidden="true"/></div>
        <Image src={this.props.logo} alt={this.props.alt} height="50px"/>
      </div>
    </section>
    );
  }
}

export default FlashCard;