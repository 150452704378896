import React, {Component} from 'react';
import { Button, Form, Alert, Col, Spinner, Row, Container, Card, Image } from 'react-bootstrap';
import countries from "i18n-iso-countries";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { API } from 'aws-amplify';
import { FlashCard } from '../../components/FlashCard'
import { CliftonSelector } from '../../components/CliftonSelector'

// TODO, support languages
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));

class Search extends Component {
  constructor(props) {
    super(props);

    this.countries = countries.getNames(this.props.i18n.language, {select: "official"})
    this.countries = Object.entries(this.countries)
    .sort(([,a],[,b]) => a>b?1:-1)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    this.months = {
      1: props.t('month/january'),
      2: props.t('month/february'),
      3: props.t('month/march'),
      4: props.t('month/april'),
      5: props.t('month/may'),
      6: props.t('month/june'),
      7: props.t('month/july'),
      8: props.t('month/august'),
      9: props.t('month/september'),
      10: props.t('month/october'),
      11: props.t('month/november'),
      12: props.t('month/december')
    }

    this.cliftonStrenghts = {
      achiever: props.t('strength/achiever'),
      activator: props.t('strength/activator'),
      adaptability: props.t('strength/adaptability'),
      analytical: props.t('strength/analytical'),
      arranger: props.t('strength/arranger'),
      belief: props.t('strength/belief'),
      command: props.t('strength/command'),
      communication: props.t('strength/communication'),
      competition: props.t('strength/competition'),
      connectedness: props.t('strength/connectedness'),
      consistency: props.t('strength/consistency'),
      context: props.t('strength/context'),
      deliberative: props.t('strength/deliberative'),
      developer: props.t('strength/developer'),
      discipline: props.t('strength/discipline'),
      empathy: props.t('strength/empathy'),
      focus: props.t('strength/focus'),
      futuristic: props.t('strength/futuristic'),
      harmony: props.t('strength/harmony'),
      ideation: props.t('strength/ideation'),
      includer: props.t('strength/includer'),
      individualization: props.t('strength/individualization'),
      input: props.t('strength/input'),
      intellection: props.t('strength/intellection'),
      learner: props.t('strength/learner'),
      maximizer: props.t('strength/maximizer'),
      positivity: props.t('strength/positivity'),
      relator: props.t('strength/relator'),
      responsibility: props.t('strength/responsibility'),
      restorative: props.t('strength/restorative'),
      self_assurance: props.t('strength/self_assurance'),
      significance: props.t('strength/significance'),
      strategic: props.t('strength/strategic'),
      woo: props.t('strength/woo')
    }


    this.years = [];
    var currentYear = new Date().getFullYear();
    var endYear = 1900;  
    while ( endYear <= currentYear ) {
      this.years.push(currentYear--);
    }

    this.genders = {
      'male': props.t('gender/male'),
      'female': props.t('gender/female'),
      'non_binary': props.t('gender/non_binary'),
    }

    this.state = {
      providers: {},
      courses: {},
      cliftonStrenghtsInput: [],
      genderInput: '',
      countryInput: '',
      mOBInput: '',
      yOBInput: '',
      topicsInput: '',
      currentProvider: false,
      spinning: false,
      userExistsError: false,
      otherError: false,
      validated: false,
      validationError: false,
    };
  }

  resetForm = () => {
    this.setState({
      providers: {},
      courses: {},
      cliftonStrenghtsInput: [],
      genderInput: '',
      countryInput: '',
      mOBInput: '',
      yOBInput: '',
      topicsInput: '',
      currentProvider: false,
      spinning: false,
      userExistsError: false,
      otherError: false,
      validated: false,
      validationError: false,
    });
  }

  handleGenderInput = (event) => {
    this.setState({genderInput: event.target.value});
  }

  handleCountryInput = (event) => {
    this.setState({countryInput: event.target.value});
  }

  handleMOBInput = (event) => {
    this.setState({mOBInput: event.target.value});
  }

  handleYOBInput = (event) => {
    this.setState({yOBInput: event.target.value});
  }

  handleTopicsInput = (event) => {
    this.setState({topicsInput: event.target.value});
  }

  handleCliftonStrenghtsInput = (value) => {
    this.setState({cliftonStrenghtsInput: value});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setProviders = (providers) => {
    this.setState({providers: providers});
  }

  setCurrentProvider = (provider) => {
    this.setState({currentProvider: provider});
  }

  setCourses = (courses) => {
    this.setState({courses: courses});
  }

  setValidationError = (error) => {
    this.setState({validationError: error});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setUserExistsError = (exists) => {
    this.setState({userExistsError: exists});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  validateForm = (event) => {
    event.preventDefault();
    this.setUserExistsError(false)
    this.setOtherError(false)
    this.setValidated(true);

    const form = this.formRef;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    else {
      this.search()
    }
  }

  search = async () => {
    this.setSpinning(true)
    this.setCourses([])

    var providers = {}
    await API.get('search', '/provider')
    .then(async (response) => {
      await response.map(async (provider) => {
        providers[provider.id] = provider
      })
    })
    .catch((error) => {
      this.setSpinning(false);
      switch(error.code) {
        default:
          this.setOtherError(true)
          break;
      }
    }); 
    this.setProviders(providers); 

    var courses = {}
    await Promise.all(Object.keys(providers).map(async (key) => {
      var provider = providers[key]
      this.setCurrentProvider(provider.id)
      await API.get('search', '/provider/' + provider.id + '/courses', {
        queryStringParameters: {
          language: 'en',
          keywords: this.state.topicsInput,
          strengths: this.state.cliftonStrenghtsInput.join('|'),
        }
      })
      .then((response) => {
        response.courses.map((course) => {
          if (courses[course.strength] === undefined) {
            courses[course.strength] = []
          }
          else {
            courses[course.strength].push({...course, provider: response.provider})
          }
        })
      })
      .catch((error) => {
        switch(error.code) {
          default:
            this.setOtherError(true)
            break;
        }
      }); 
    }))

    this.setCourses(courses)    
    this.setCurrentProvider(false)
    this.setSpinning(false)
  }

  render() {
    var submitButton = <Button variant="primary" type="submit">{this.props.t('button/label/search')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary" type="submit" disabled><Spinner
        as="span"
        animation="border"
        size="sm"        
        role="status"
        aria-hidden="true"/> <span className="sr-only">{this.props.t('button/label/search')}</span>
        </Button>
    }

    var flashCard = '';
    if (this.state.currentProvider ) {
      var logo = this.state.providers[this.state.currentProvider].logo
      var providerName = this.state.providers[this.state.currentProvider].name
      flashCard = <FlashCard message={this.props.t('search/flashcard/search_in_progress')} logo={logo} alt={providerName}/>
    }

    if (Object.keys(this.state.courses).length > 0) {
      flashCard = <p className="my-3 text-center">{this.props.t('search/flashcard/your_skills')}</p>
    }

    return <><Container className="search py-5">
    <Card>
      <Form noValidate validated={this.state.validated} onSubmit={this.validateForm} className="justify-content-center search" ref={(formRef) => { this.formRef = formRef }}>
        <Card.Body>
          <Card.Title>{this.props.t('search/title/search')}</Card.Title>
          <Card.Text>
            <Alert variant='danger' show={this.state.otherError}>
              {this.props.t('failure_alert/general_error')}
            </Alert>
            <Alert variant='danger' show={this.state.validationError}>
              {this.props.t('failure_alert/validation_error')}
            </Alert>
            <p>{this.props.t('search/description')}</p>
            <Row className="justify-content-center form-items">

              <Form.Group as={Col} xs={12} lg={4} controlId="group-country" className="mb-3">
                <Form.Label className="required">{this.props.t('form/label/country')}</Form.Label>
                <Form.Select
                  required
                  value={this.state.countryInput}
                  onChange={this.handleCountryInput}>
                    <option value="">{this.props.t('form/placeholder/select_country')}</option>
                  {
                    Object.keys(this.countries).map((key) => {
                      return <option value={key}>{this.countries[key]}</option>
                    })
                  }
                </Form.Select>
              </Form.Group>


              <Form.Group as={Col} xs={12} lg={4} controlId="group-yob" className="mb-3">
                <Form.Label className="required">{this.props.t('form/label/myob')}</Form.Label>

                <Row className="justify-content-center form-items">
                  <Form.Group as={Col} xs={6} controlId="group-mob">
                    <Form.Select
                      required
                      value={this.state.mOBInput}
                      onChange={this.handleMOBInput}>
                        <option value="">{this.props.t('form/placeholder/select_month')}</option>
                      {
                        Object.keys(this.months).map((key) => {
                          return <option value={key}>{this.months[key]}</option>
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} controlId="group-yob">
                    <Form.Select
                      required
                      value={this.state.yOBInput}
                      onChange={this.handleYOBInput}>
                      <option value="">{this.props.t('form/placeholder/select_year')}</option>
                      {
                        this.years.map( (year) => 
                        <option key={year}>{year}</option> )
                      }
                    </Form.Select>
                  </Form.Group>
                </Row>
              </Form.Group>
            
              <Form.Group as={Col} xs={12} lg={4} controlId="group-gender" className="mb-3">
                <Form.Label>{this.props.t('form/label/gender')}</Form.Label>
                <Form.Select
                  value={this.state.genderInput}
                  onChange={this.handleGenderInput}>
                  <option value="">{this.props.t('form/placeholder/select_gender_optional')}</option>
                  {
                    Object.keys(this.genders).map((gender) => {
                      return <option value={gender}>{this.genders[gender]}</option>
                    })
                  }
                </Form.Select>
              </Form.Group>
            </Row>
            <Form.Group controlId="group-topics" className="justify-content-center form-items mb-3">
              <CliftonSelector
                required
                value={this.state.cliftonStrenghtsInput} 
                onChange={(cliftonStrengths) => { this.handleCliftonStrenghtsInput(cliftonStrengths) }}
              />
            </Form.Group>
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <div class="text-end">
          <p>{this.props.t('search/description/agreeing_to_privacy_notice')} <Link to="/privacy-notice"> {this.props.t('navigation/link/privacy_notice')}</Link>.</p>
          
          {submitButton}
          </div>
        </Card.Footer>
      </Form>
    </Card>
    {flashCard}
    {
      this.state.cliftonStrenghtsInput.map( (strength) => {
        if (this.state.courses[strength] !== undefined) 
        return <>
          <Card className="my-4">
            <Card.Body>
              <Card.Title>{this.state.courses[strength][0]['skill']}</Card.Title>
              <Row class="results">
                {
                  this.state.courses[strength].map( (course) => (
                    <Col xs={12} md={6} lg={4} className="my-4">
                      <Card>
                      <Card.Link href={course.url} target="_blank"><Card.Img variant="top" src={course.image} /></Card.Link>
                        <Card.Body>
                          <Card.Title><Card.Link href={course.url} className="text-decoration-none" target="_blank">{course.title}</Card.Link></Card.Title>
                          <Card.Text>{course.description}</Card.Text>
                        </Card.Body>
                        <Card.Footer className="d-flex flex-row justify-content-between">
                          <Card.Link href={course.provider.url} className="text-decoration-none" target="_blank"><Image src={course.provider.logo} height="30px" alt={course.provider.name}/></Card.Link>
                          <div className="price">{course.price.string}</div>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))
                }
              </Row>
            </Card.Body>
          </Card>
          </>
      })
    }
  </Container>
  <Outlet/></>
  }
}

Search.propTypes = {
  onSearch: PropTypes.func
};

export default withTranslation()(Search)