import React, {Component} from 'react';
import { withTranslation } from "react-i18next";

// Router
import { Routes, Route } from "react-router-dom";
import { withRouter } from '../../util/Router';

// Comps
import { Container, Button } from 'react-bootstrap';

import { Search } from '../../components/Search'
import { RemindPassword } from '../../components/RemindPassword'
import { SignIn } from '../../components/SignIn'
import { SignUp } from '../../components/SignUp'
import { MyAccount } from '../../components/MyAccount'
import { Empty } from '../../components/Empty'
import { ValidateEmail } from '../../components/ValidateEmail'
import { ChangePassword } from '../../components/ChangePassword'

import { About } from '../../components/About'
import { PrivacyNotice } from '../../components/PrivacyNotice'
import { Contract } from '../../components/Contract'

import { TopNavigation } from '../../components/TopNavigation'
import { Footer } from '../../components/Footer'

// Icons
import { IconContext } from "react-icons";

// AWS Amplify
import { Auth, Hub } from 'aws-amplify';

// Styles & resources
import './scss/layout.scss';

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      mustSignContract: false,
      mustCreateOrganisation: false,
      loading: false,
      user: {},
      organisations: [],
      activeOrganisation: null,
      activitiesTimestamp: Date.now()
    }

    // Listen to AWS Amplify Auth events to update state when necessary
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          let user = data.payload.data;
          this.setState({
            authenticated: true,
            mustSignContract: user.attributes['custom:signed_contract'] == 'true' ? false : true,
            user: user})
          this.refreshOrganisations()
          break;
        case 'signOut':
          this.setState({
            authenticated: false,
            mustSignContract: false,
            user: false})
          break;
        default:
          break;
      }
    });
  }

  async componentDidMount() {
    await this.refreshUser()
    if (this.state.authenticated) {
      await this.refreshOrganisations()
    }
  }

  refreshOrganisations = async () => {}

  refreshUser = async (bypassCache = false) => {
    await Auth.currentAuthenticatedUser({bypassCache: bypassCache})
      .then(user => {
        this.setState({
          authenticated: true, 
          mustSignContract: user.attributes['custom:signed_contract'] == 'true' ? false : true,
          user: user})
      });
  }

  signIn = () => {
    this.props.navigate("/")
  }

  confirmSignUp = (email) => {
    this.props.navigate("/confirm-signup/" + email)
  }

  verifyEmail = (email) => {
    this.props.navigate("/account/verify-email/" + email)
  }

  changePassword = (email) => {
    this.props.navigate("/account/change-password/" + email)
  }

  updateUser = () => {
    this.refreshUser(true)
  }

  createOrganisation = (organisation) => {
    this.refreshOrganisations()
    this.changeOrganisation(organisation)
  }

  updateOrganisation = (organisation) => {
    this.refreshOrganisations()
  }

  deleteOrganisation = () => {
    this.refreshOrganisations()
    this.props.navigate("/")
  }

  changeOrganisation = (organisation) => {
    this.setState({activeOrganisation: organisation});
    window.localStorage.setItem('activeOrganisationId', organisation.organisationId);
    this.props.navigate("/activities")
  }

  createActivity = (activity) => {
    this.setState({activitiesTimestamp: Date.now()});
  }

  updateActivity = (activity) => {
    this.setState({activitiesTimestamp: Date.now()});
  }

  deleteActivity = () => {
    this.setState({activitiesTimestamp: Date.now()});
  }

  signOut = () => {
    Auth.signOut()
    this.props.navigate("/")
  }

  goHome = () => {
    this.props.navigate("/")
  }

  goToActivities = () => {
    this.props.navigate("/activities")
  }

  goToAccount = () => {
    this.props.navigate("/account")
  }

  goToCalendar = () => {
    this.props.navigate("/calendar")
  }

  render() {
    let contract = <></>
    if (this.state.mustSignContract) {
      contract = <Contract 
        opened={true} 
        user={this.state.user} 
        onUpdate={this.updateUser}/>
    }

    let content
    if (this.state.authenticated) {
      content = <>
          <Routes>
            <Route path="/" element={<Search onSearch={() => {}}/>}/>
            <Route path="/privacy-notice" element={<PrivacyNotice opened={true} onClose={this.goHome}/>}/>
            <Route path="/about" element={<About opened={true} onClose={this.goHome}/>}/>
            <Route path="/account" element={<MyAccount 
              user={this.state.user} 
              onUpdate={this.updateUser}
              onVerifyEmail={(email) => { this.verifyEmail(email) }}
              onChangeEmail={(email) => { this.verifyEmail(email) }}
              onChangePassword={(email) => { this.changePassword(email)}}/>}>
              <Route path={"change-password/:email"} element={<ChangePassword 
                opened={true} 
                onClose={this.goToAccount} 
                onChanged={this.updateUser}/>}/>
              <Route path={"verify-email/:email"} element={<ValidateEmail 
                opened={true} 
                onClose={this.goToAccount} 
                onValidate={this.updateUser}
                confirmSignUp={false}/>}/>
            </Route>
          </Routes>
      </>
    }
    else {
      content = <>
        <Routes>
          <Route path="/" element={<Empty title={this.props.t('contract/title/contract')} message={this.props.t('contract/body')} cta={this.props.t('button/label/sign_in')} ctaLink="signin"/>}>
            <Route path="signup" element={<SignUp opened={true} onClose={this.goHome} onSignUp={this.confirmSignUp}/>}/>
            <Route path="signin" element={<SignIn opened={true} onClose={this.goHome} onSignIn={this.signIn}/>}/>
            <Route path="remind-password" element={<RemindPassword opened={true} onClose={this.goHome} onCodeSent={(email) => { this.changePassword(email)}}/>}/>
            <Route path="account/change-password/:email" element={<ChangePassword 
                opened={true} 
                onClose={this.goHome} 
                onChanged={this.updateUser}/>}/>
            <Route path="confirm-signup/:email" element={<ValidateEmail 
              opened={true} 
              onClose={this.goHome} 
              onValidate={this.updateUser}
              confirmSignUp={true}/>}/>
          </Route>
          <Route path="/privacy-notice" element={<PrivacyNotice opened={true} onClose={this.goHome}/>}/>
          <Route path="/about" element={<About opened={true} onClose={this.goHome}/>}/>
        </Routes>
        
      </>
    }

    return <>
      <IconContext.Provider value={{ className: "layout-icons", size: "1.75em" }}>
        <Container fluid className='g-0 main-container'>
          <section className='content d-flex flex-column flex-grow-1 g-3'>
            <Container fluid as="header" className="top-navigation">
              <TopNavigation 
                authenticated={this.state.authenticated} 
                organisations={Array.from(this.state.organisations.values())} 
                activeOrganisation={this.state.activeOrganisation} 
                mustCreateOrganisation={this.state.mustCreateOrganisation} 
                onChangeOrganisation={(organisation) => {this.changeOrganisation(organisation)}}
                onSignOut={this.signOut}/>
            </Container>
            <Container fluid className="flex-grow-1 d-flex justify-content-center">
              {content}
            </Container>
            <Container fluid as="footer" className='py-3 bottom-footer'>
              <Footer/>
            </Container>
          </section>
          {contract}
        </Container>

      </IconContext.Provider>
      </>
      
    }
}

export default withRouter(withTranslation()(Layout)); 