import React, {Component} from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown'

class PrivacyNotice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
      return (<Container className="search py-5">
          <h1>{this.props.t('privacy_notice/title/privacy_notice')}</h1>
          <ReactMarkdown>{this.props.t('privacy_notice/body')}</ReactMarkdown>
        </Container>
      );
  }
}

export default withTranslation()(PrivacyNotice);