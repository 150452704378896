import React, {Component} from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";

class Empty extends Component {
  constructor(props) {
    super(props); 
  }
 
  render() {
    return (<>
    <Container className="container my-3">
      <h1 className="text-center">{this.props.title}</h1>
      <div className="my-3 text-center">
        {this.props.message}
      </div>
      <div className="pane-footer text-center">
        <Link to={this.props.ctaLink}><Button variant="primary" type="submit">{this.props.cta}</Button></Link>
      </div>
    </Container>

    <Outlet/></>
    );
  }
}

export default Empty;