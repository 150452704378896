import React, {Component} from 'react';
import { withTranslation } from "react-i18next";
import { Nav, Navbar} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import { IoMdPerson, IoMdLogOut, IoMdLogIn, IoMdLock } from "react-icons/io";

// Styles & resources
import './scss/top_navigation.scss';
import logo from './resources/logo.svg'; 

class TopNavigation extends Component {
  constructor(props) {
    super(props); 
  }

  handleMenu = (selectedKey) => {
    switch(selectedKey) {
      case 'signout':
        this.props.onSignOut();
        break;
    }
  }

  handleDropdown = (index) => {
    if (index !== null) {
      this.props.onChangeOrganisation(this.props.organisations[index] ?? null);
    }
  }
 
  render() {
    let menuItems

    if (this.props.authenticated) {

      menuItems = <>
        <NavLink to="/account" className="nav-link"><IoMdPerson/> {this.props.t('navigation/link/account_settings')}</NavLink>
        <Nav.Link eventKey="signout" className="nav-link"><IoMdLogOut/> {this.props.t('navigation/link/sign_out')}</Nav.Link>
      </>

    }
    else {
      menuItems = <>
          <NavLink to="/signin" className="nav-link"><IoMdLogIn/> {this.props.t('navigation/link/sign_in')}</NavLink>
          <NavLink to="/signup" className="nav-link"><IoMdPerson/> {this.props.t('navigation/link/sign_up')}</NavLink>
          <NavLink to="/remind-password" className="nav-link"><IoMdLock/> {this.props.t('navigation/link/remind_password')}</NavLink>
        </>
    }
    
    return (
      <Navbar expand="lg">
        <Navbar.Brand className="py-2" href="/"><img src={logo} alt="" className="logo" height="100"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">
          <Nav onSelect={(selectedKey) => this.handleMenu(selectedKey)}>
            {menuItems}
          </Nav>
        </Navbar.Collapse>
        <Nav onSelect={(selectedKey) => this.handleMenu(selectedKey)} className="justify-content-end wallet-nav"></Nav>
      </Navbar>
    );
  }
}

TopNavigation.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  activeOrganisation: PropTypes.object,
  organisations: PropTypes.array,
  onChangeOrganisation: PropTypes.func,
  onSignOut: PropTypes.func
};

export default withTranslation()(TopNavigation);