const awsConfig = {
  "aws_project_region": "eu-west-1",
  "Auth": {
      "identityPoolId": "eu-west-1:59acd4db-9821-43aa-9a2d-d2a76f0ea893",
      "region": "eu-west-1",
      "userPoolId": "eu-west-1_45NzAZ92k",
      "userPoolWebClientId": "3uivo5v9qqg9f88v5alp5ti8nv"
  },
  "API": {
    "endpoints": [
        {
            "name": "search",
            "endpoint": "https://28xsqn7t1m.execute-api.eu-west-1.amazonaws.com/prod"
        }
    ]
},
};

export default awsConfig;